import { CSSProperties } from 'react'
import { createTheme, ThemeOptions } from '@mui/material'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import '@fontsource/roboto'
import '@fontsource/barlow-condensed'
import '@fontsource/inter'

//** Custom Variant */
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		demoVariant: true
	}
}

export interface ExtendedTypographyOptions extends TypographyOptions {
	demoVariant: CSSProperties
}

declare module '@mui/material/styles' {
	interface BreakpointOverrides {
		xxs: true;
		xs: true;
		sm: true;
		md: true;
		lg: true;
		xl: true;
	}
}

//** Custom Theme */
const ludusTheme = createTheme({
	breakpoints: {
		values: {
			xxs: 0,
			xs: 370,
			sm: 620,
			md: 860,
			lg: 980,
			xl: 1400,
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					height: '100%',
					width: '100%',
				},
				button: {
					'&.MuiButton-outlined': {
						'&:hover': {
							boxShadow: '0px 15px 20px',
						},
					},
				},
			},
		},
		MuiTableContainer: {
			styleOverrides: {
				root: {
					'&.darkened': {
						opacity: 0.3,
						pointerEvents: 'none',
					},
				},
			},
		},
		MuiButtonRoot: {
			root: {
				style: {
					'&.MuiButton-outlined': {
						'&:hover': {
							border: '2px solid',
							boxShadow: '0px 15px 20px',
						},
					},
				},
			},
		},
		MuiFormLabelRoot: {
			MuiInputLabelRoot: {
				style: {
					color: '#757575',
				},
			},
		},
		MuiDrawer: {
			MuiDrawerPaper: {
				style: {
					width: 240,
				},
			},
		},
	},
	palette: {
		// mode:"dark",
		primary: {
			main: '#00E495',
			light: '#00262A',
			dark: '#002126',
			contrastText: '#F0F0F0',
		},
		secondary: {
			main: '#002B31',
			light: '#004043',
			dark: '#161616',
		},
		error: {
			main: '#dc3545',
			light: '#ff6b70',
			dark: '#a3001e',
		},
		warning: {
			main: '#FFE300',
			light: '#ffe366',
			dark: '#c08200',
		},
		info: {
			main: '#346165',
			light: '#618f93',
			dark: '#343F40',
		},
		success: {
			main: '#35e398',
			light: '#77ffc9',
			dark: '#00b069',
		},
	},
	typography: {
		button: {
			fontFamily: 'Inter',
			fontWeight: '700',
			textTransform: 'none',
		},

		h1: {
			fontFamily: 'Barlow Condensed',
			fontWeight: '700',
		},
		h2: {
			fontFamily: 'Barlow Condensed',
			fontWeight: '700',
		},
		h3: {
			fontFamily: 'Barlow Condensed',
		},
		h4: {
			fontFamily: 'Barlow Condensed',
		},
		h5: {
			fontFamily: 'Barlow Condensed',
		},
		h6: {
			fontFamily: 'Barlow Condensed',
		},
		subtitle1: {
			fontFamily: 'Roboto',
			fontWeight: '700',
		},
		subtitle2: {
			fontFamily: 'Roboto',
		},
		body1: {
			fontFamily: 'Barlow Condensed',
		},
		body2: {
			fontFamily: 'Barlow Condensed',
		},
		//** Custom Variant */
		demoVariant: {
			fontSize: '6rem',
		},
	} as ExtendedTypographyOptions,
} as ThemeOptions)

//** Custom styles constants */

export const drawerWidth = '20vw'

export const drawerWidthTablet = '45vw'

export const tableContainerStyles = {
	backgroundColor: ludusTheme.palette.primary.dark,
	borderRadius: 4,
	marginTop: 3,
	minHeight: '700px',
}

export const headerBoxStyles = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	backgroundColor: ludusTheme.palette.secondary.dark,
	padding: '10px',
	borderRadius: '4px 4px 0 0',
}

export const headerTypographyStyles = {
	color: 'white',
	fontWeight: '600',
	marginLeft: '20px',
	fontSize: { xxs: 12, xs: 18, sm: 24, md: 24, lg: 24, xl: 24 },
}

export const actionButtonStyles = {
	backgroundColor: ludusTheme.palette.secondary.main,
	color: 'white',
	fontSize: { xxs: 10, xs: 12, sm: 12, md: 12, lg: 16, xl: 18 },
	fontFamily: 'Barlow Condensed !important',
	fontWeight: '500',
	marginRight: { xxs: '5px', xs: '5px', sm: '10px', md: '20px', lg: '20px', xl: '20px' },
	borderRadius: '25px',
	padding: { xxs: '5px 10px', xs: '5px 10px', sm: '10px 10px', md: '10px 16px', lg: '10px 16px', xl: '10px 16px' },
	'& .MuiButton-startIcon > *:nth-of-type(1)': {
		fontSize: {
			xxs: '14px',
			xs: '16px',
			sm: '18px',
			md: '20px',
			lg: '22px',
			xl: '24px',
		},
	}
}

export const deleteButtonStyles = {
	backgroundColor: '#9E3838',
	color: 'white',
	fontSize: { xxs: 10, xs: 12, sm: 12, md: 12, lg: 16, xl: 18 },
	fontFamily: 'Barlow Condensed !important',
	fontWeight: '500',
	marginRight: { xxs: '5px', xs: '5px', sm: '10px', md: '20px', lg: '20px', xl: '20px' },
	borderRadius: '25px',
	padding: { xxs: '5px 10px', xs: '5px 10px', sm: '10px 10px', md: '10px 16px', lg: '10px 16px', xl: '10px 16px' },
	'& .MuiButton-startIcon > *:nth-of-type(1)': {
		fontSize: {
			xxs: '14px',
			xs: '16px',
			sm: '18px',
			md: '20px',
			lg: '22px',
			xl: '24px',
		},
	}
}

export const filterBoxStyles = {
	display: 'flex',
	flexDirection: 'column',
	width: {
		xxs: '100%',
		xs: '100%',
		sm: '100%',
		md: '20%',
		lg: '18%',
		xl: '16%',
	},
	backgroundColor: ludusTheme.palette.primary.light,
	padding: '12px',
	textAlign: 'left',
}

export const checkboxGridStyles = {
	width: {
		xxs: '10%',
		xs: '10%',
		sm: '5%',
		md: '5%',
		lg: '5%',
		xl: '3%',
	},
	marginLeft: {
		xxs: '5px',
		xs: '5.5px',
		sm: '5.5px',
		md: '0',
		lg: '0',
		xl: '0',
	},
	backgroundColor: ludusTheme.palette.primary.dark,
}

export const checkboxTableContainerStyles = {
	backgroundColor: 'transparent',
	boxShadow: 'none',
	textAlign: 'center',
	marginTop: {
		xxs: '35px',
		xs: '30px',
		sm: '35px',
		md: '30px',
		lg: '30px',
		xl: '30px',
	},
	marginBottom: '0px',
}

export const infoGridStyles = {
	width: {
		xxs: '75%',
		xs: '75%',
		sm: '75%',
		md: '70%',
		lg: '75%',
		xl: '80%',
	},
	marginLeft: {
		xxs: '5px',
		xs: '5px',
		sm: '5px',
		md: '0',
		lg: '0',
		xl: '0',
	},
}

export const boxFiltersButtons = {
	display: 'flex',
	flexDirection: {
		xxs: 'column',
		xs: 'column',
		sm: 'row',
		md: 'column',
		lg: 'column',
		xl: 'column',
	},
	justifyContent: {
		xxs: 'center',
		xs: 'center',
		sm: 'center',
		md: 'flex-start',
		lg: 'flex-start',
		xl: 'flex-start',
	},
	gap: {
		xxs: '0px',
		xs: '0px',
		sm: '5px',
		md: '0px',
		lg: '0px',
		xl: '0px',
	}
}

export const boxFilterButtonRemove = {
	display: 'flex',
	flexDirection: {
		xxs: 'column',
		xs: 'column',
		sm: 'row',
		md: 'column',
		lg: 'column',
		xl: 'column',
	},
	justifyContent: {
		xxs: 'center',
		xs: 'center',
		sm: 'center',
		md: 'flex-start',
		lg: 'flex-start',
		xl: 'flex-start',
	}
}

export const infoTableContainerStyles = {
	backgroundColor: ludusTheme.palette.info.dark,
	boxShadow: 'none',
	border: '2px solid white',
	textAlign: 'center',
	borderRadius: '20px',
	marginTop: '30px',
}

export const tableHeadStyles = {
	backgroundColor: ludusTheme.palette.secondary.dark,
	'& th': {
		textAlign: 'center',
		fontFamily: 'Barlow Condensed',
		fontSize: { xs: 10, sm: 10, md: 10, lg: 12, xl: 16 },
		fontWeight: 'semibold !important',
	},
}

export const bordersTableStyles = {
	'& .MuiTableRow-root > .MuiTableCell-root:last-child': {
		borderRight: 0,
	},
	'& .MuiTableRow-root:last-child > .MuiTableCell-root': {
		borderBottom: 0,
	},
	'& .MuiTableRow-root:first-of-type > .MuiTableCell-root': {
		borderBottom: '1px solid white',
	},
	'& .MuiTableCell-head': {
		fontWeight: 'bold',
	},
}

export const tdTableStyles = {
	'& td': {
		fontFamily: 'Barlow Condensed',
		fontWeight: 'semibold !important',
		color: 'white',
		textAlign: 'center',
		fontSize: { xxs: 12, xs: 10, sm: 10, md: 10, lg: 12, xl: 16 },
	},
}

export const buttonCourseStyles = {
	fontSize: { xxs: 10, xs: 10, sm: 12, md: 12, lg: 14, xl: 16 },
	color: ludusTheme.palette.primary.main,
	padding: '0 !important',
	fontFamily: 'Barlow Condensed',
	textTransform: 'none',
	fontWeight: '400',
	'&:hover': {
		textDecoration: 'underline',
		background: 'transparent',
	},
}

export const buttonInstructorStyles = {
	color: 'white',
	textAlign: 'center',
	padding: '0 !important',
	fontFamily: 'Barlow Condensed',
	textTransform: 'none',
	fontWeight: '400',
	'&:hover': {
		background: 'transparent',
	},
	fontSize: { xs: 10, sm: 12, md: 12, lg: 16 },
}

export const filterText = {
	color: 'white',
	fontWeight: '400',
	marginLeft: '2px',
	marginBottom: '8px',
}

export const popoverStyles = {
	borderRadius: '15px',
	backgroundColor: ludusTheme.palette.primary.contrastText,
	padding: '0px',
	color: 'white',
	minWidth: '300px',
}

export const genericModalBoxStyles = {
	padding: '0px',
	color: 'white',
	minWidth: '300px',
	backgroundColor: '#F0F0F0',
	borderRadius: '30px',
	position: 'relative',
}

export const textFieldModalTimeStyles = {
	backgroundColor: '#BEBEBE',
	borderRadius: '50px',
	padding: '1px',
	color: 'white',
	minWidth: '150px',
	textAlign: 'center',
	marginBottom: '20px',
	fontSize: { xs: 10, sm: 12, md: 12, lg: 18 },
}

export const dateTimePaperPropsStyles = {
	sx: {
		'.MuiPickersCalendarHeader-root': {
			display: 'flex',
			alignItems: 'center',
			justifyItems: 'center',
		},
		'.MuiPickersCalendarHeader-root:first-child': {
			order: 0,
			paddingRight: '20px',
			paddingLeft: '20px',
		},
		'.MuiPickersArrowSwitcher-root': {
			display: 'inline-flex',
		},
		'.MuiPickersCalendarHeader-label': {
			fontWeight: 'bold',
			width: '85px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		'.MuiPickersArrowSwitcher-spacer': {
			width: '220px',
		},
		'.css-31ca4x-MuiPickersFadeTransitionGroup-root': {
			display: 'flex',
			position: 'absolute',
			paddingLeft: '105px',
			fontSize: { xs: 10, sm: 12, md: 12, lg: 14, xl: 16 },
		},
		'.css-9reuh9-MuiPickersArrowSwitcher-root': {
			marginLeft: '-2px',
		},
		'.MuiPickersArrowSwitcher-button': {
			paddingRight: '9px',
		},
		'.MuiPickersDay-root': {
			borderRadius: '8px',
			fontWeight: 'bold',
		},
		'.Mui-selected': {
			backgroundColor: '#00E495 !important',
			color: 'white',
		},
		'.MuiPickersDay-dayOutsideMonth': {
			color: '#B0B0B0 !important',
		},
		'.MuiDayCalendar-weekDayLabel': {
			fontWeight: 'bold',
			color: 'black',
		},
		'.MuiPickersDay-today': {
			border: 'none !important',
		},
	},
}

export const customAppStyles = {
	offset: ludusTheme.mixins.toolbar,
	rootBox: { display: 'flex' },
	contentBox: {
		flexGrow: 1,
		backgroundColor: 'transparent !important',
		padding: { xxs: 0, xs: 0, sm: 0, md: ludusTheme.spacing(3), lg: ludusTheme.spacing(3), xl: ludusTheme.spacing(3) },
		paddingX: { xxs: 0, xs: 0, sm: 0, md: ludusTheme.spacing(3), lg: ludusTheme.spacing(3), xl: ludusTheme.spacing(3) },
		marginTop: { xxs: 3, xs: 3, sm: 3, md: 0, lg: 0, xl: 0 },
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
	},
	drawerBox: { display: { xs: 'none', md: 'block' } },
}

export const customHeaderStyles = {
	offset: ludusTheme.mixins.toolbar,
	menuButton: {
		marginLeft: ludusTheme.spacing(2),
		display: 'block',
	},
	navBarTittle: {
		flexGrow: 1,
		textDecoration: 'none',
		color: 'inherit',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
		alignItems: 'center',
		alignContent: 'stretch',
	},
}

export const calendarStyles = {
	'--color-accent': '#00E495',
	'--color-text-on-accent': '#000000',
	display: 'inline-block',
	borderRadius: '3px',
	backgroundColor: '#F0F0F0',
	color: 'black',
	fontWeight: 'bold',
	fontFamily: 'Barlow Condensed, sans-serif',
}

export const calendarDayStyles = `
  calendar-month::part(day) {
    font-weight: bold;
    font-family: 'Barlow Condensed', sans-serif;
	border-radius: 20%;
  }
  calendar-month::part(month-label) {
    font-weight: bold;
    font-family: 'Barlow Condensed', sans-serif;
  }
  calendar-month::part(range-inner) {
    background-color: #b2f5ea; // Tono más claro para los días intermedios
  }
  calendar-month::part(range-start),
  calendar-month::part(range-end) {
    background-color: #00E495; // Color para el inicio y el fin del rango
  }
`

export const calendarButtonStyles = `
  calendar-range::part(button) {
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    border: none;
    background: none;
  }
 calendar-range::part(heading) {
	padding:40px
  }
  calendar-range::part(button):hover {
    background-color: #E0E0E0;
    border-radius: 50%;
  }
`

export const calendarButtonArrows = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '40px',
	height: '40px',
	borderRadius: '50%',
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: '#E0E0E0',
	},
}

export const calendarRangeStyles = {
	backgroundColor: '#F0F0F0',
	color: 'black',
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	gap: '1em',
	fontFamily: 'Barlow Condensed, sans-serif',
}

export const todayStyles = `
  .today {
    border: 2px solid ${ludusTheme.palette.primary.main};
    border-radius: 50%;
    color: ${ludusTheme.palette.primary.main};
    font-weight: bold;
  }
`

export const filterButtonPeriodModalStyles = {
	fontFamily: 'Barlow Condensed',
	boxShadow: 'none !important',
	padding: '0',
	minWidth: '150px',
	maxHeight: '30px',
	'&:hover': {
		boxShadow: 'none',
	},
	fontSize: { xs: 10, sm: 12, md: 12, lg: 14 },
}

export const toggleButtonGroupStyles = {
	borderRadius: '20px',
	backgroundColor: '#BEBEBE',
	display: 'flex',
	padding: '2px',
	gap: 1,
	alignItems: 'center',
	'& .MuiToggleButton-root': {
		border: 'none',
		borderRadius: '50px',
		color: '#707070',
	},
}

export const toggleButtonStyles = {
	color: 'black !important',
	borderRadius: '50px',
	padding: '0 20px',
	minWidth: '100px',
	maxHeight: '30px',
}

export const circularProgressStyles = {
	margin: { xs: '10px', md: '10px', lg: '10px', xl: '10px' },
	width: {
		xxs: '40px !important',
		xs: '40px !important',
		md: '40px !important',
		lg: '60px !important',
		xl: '70px !important',
	},
	height: {
		xxs: '40px !important',
		xs: '40px !important',
		md: '40px !important',
		lg: '60px !important',
		xl: '70px !important',
	},
}

export const propsNewFormationDialog = {
	borderRadius: '20px',
	minWidth: {
		xxs: '100vw',
		xs: '90vw',
		sm: '80vw',
		md: '700px',
		lg: '700px',
		xl: '700px',
	},
	height: '450px',
	padding: '50px',
	backgroundColor: '#002126',
	textAlign: 'center',
	position: 'relative',
}

export const styleButtonsCalendar = {
	backgroundColor: '#004043',
	borderRadius: '30px',
	color: '#668C8E',
	fontWeight: '600',
	minWidth: { xxs: '50%', xs: '50%', sm: '40%', md: '40%', lg: '40%', xl: '40%' },
	minHeight: '50px',
	marginLeft: '0px',
	paddingLeft: '0px',
	fontFamily: 'Barlow Condensed !important',
	fontSize: { xxs: 10, xs: 10, sm: 10, md: 10, lg: 12, xl: 14 },
	'&:hover': {
		backgroundColor: '#003e47',
		border: '2px solid white',
	},
}

export const textfieldNewFormationDialog = {
	backgroundColor: '#004043',
	borderRadius: '30px',
	color: 'white',
	fontWeight: '400',
	marginTop: '30px',
	minWidth: { xxs: '250px', xs: '220px', sm: '300px', md: '500px', lg: '500px', xl: '500px' },
	fontFamily: 'Barlow Condensed !important',
	fontSize: { xxs: 10, xs: 10, sm: 12, md: 12, lg: 18 },
	'&.Mui-focused': {
		backgroundColor: '#004043',
		borderColor: 'white',
		color: 'white',
	},
	'& .MuiOutlinedInput-notchedOutline': {
		borderColor: 'transparent',
	},
	'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
		borderColor: 'white',
	},
}

export const styleBoxPeriodModal = {
	padding: '20px',
	backgroundColor: '#F0F0F0',
	color: 'white',
	borderRadius: '20px',
	margin: 'auto',
	'@media (max-width: 620px)': {
		width: '100vw',
		padding: '40px',
		borderRadius: '0',
		margin: '0',
	}
}

export default ludusTheme
